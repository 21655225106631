@import '../../../../scss/theme-bootstrap';

.product-grid.product-grid-v1 {
  @if $cr22 {
    &.product-grid--theme-white .product-brief__image {
      background: $color-white;
    }
    &.product-grid--theme {
      @each $color, $gradient in $gradients {
        &-#{$color} {
          .product-brief__image {
            @include gradient-background($gradient);
          }
        }
      }
    }
    .product-brief {
      &__info {
        padding: 0 20px;
        position: relative;
        z-index: 11;
      }
      &__image {
        aspect-ratio: 4/5;
        max-width: 100%;
      }
      &__description {
        .product-name {
          color: $color-neutral-dark-gray;
          font-weight: 600;
          line-height: 1.6;
        }
      }
      &__price {
        font-weight: 300;
        letter-spacing: .01em;
        .product-price {
          line-height: 1.5;
        }
      }
      &__cta {
        .link {
          font-size: 15px;
          letter-spacing: .01em;
          line-height: 1.5;
        }
      }
    }
    .slick-slide .product-brief__info {
      @include breakpoint($landscape-down) {
        padding: 0;
      }
    }
    .product-image--med {
      max-width: unset;
    }
    div.product-brief__extras-container {
      padding: 0 20px 10px;
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 20px;
        padding-#{$rdirection}: 20px;
      }
    }
    .product-brief__footer {
      padding: 0;
    }
    @include breakpoint($medium-up) {
      .slick-prev {
        left: 5px;
      }
      .slick-next {
        right: 5px;
      }
    }
    .content-block & {
      div.product-grid__content {
        @include breakpoint($landscape-up) {
          margin: 0 20px;
        }
      }
    }
    &.product-grid--peeking {
      .slick-slide {
        margin-#{$rdirection}: 16px;
        @include breakpoint($medium-up) {
          margin-#{$rdirection}: 0;
        }
      }
    }

    body.html .block-template-product-grid-v1 &.product-grid--carousel {
      .slick-prev,
      .slick-next {
        @include breakpoint($large-up) {
          margin-top: 20px;
          max-height: 534px;
        }
      }
      .product-brief--show-shade-size {
        .slick-prev,
        .slick-next {
          @include breakpoint($large-up) {
            margin-block-start: 0;
          }
        }
      }
    }
    body.html & .product-brief__cta {
      margin-#{$ldirection}: 0;
    }
    .mpp-container & {
      div.product-brief {
        &__footer {
          padding: 0 20px;
        }
      }
    }
  }
  .product-brief--show-shade-size {
    .product-brief {
      &__header {
        margin-top: 15px;
        padding: 0 15px;
        opacity: 1;
        @include breakpoint($medium-up) {
          padding: 0;
        }
      }
      &__cta {
        .button {
          letter-spacing: 0.01em;
          :not(.mpp-container) & {
            background-color: transparent;
            border: 0;
            border-bottom: 1px solid $color-neutral-dark-gray;
            color: $color-neutral-dark-gray;
            min-width: auto;
            padding: 0;
            width: auto;
            float: $ldirection;
            &--disabled {
              border-bottom: none;
            }
          }
        }
      }
      &__description {
        .product-name {
          margin-block-end: 0;
        }
        .product-subline {
          margin-block-end: 3px;
        }
      }
      &__price {
        .product-price {
          margin-block-start: 3px;
        }
      }
      &__shadename {
        padding-block-end: 15px;
        padding-inline-end: 0;
        color: $color-darker-gray;
      }
      &__sku {
        max-width: 380px;
        .product-section-title {
          display: none;
        }
        .sku-list {
          max-width: 406px;
          border-bottom: none;
          padding-bottom: 0;
          &__item {
            margin-bottom: 5px;
            margin-inline-end: 8px;
            opacity: 1;
            @include breakpoint($medium-up) {
              margin-inline-end: 10px;
            }
            &.slick-slide::after {
              display: none;
            }
          }
          &__button {
            padding: 5px;
            min-width: 60px;
            line-height: 1.2;
            @include breakpoint($medium-up) {
              padding: 5px;
              min-width: 58px;
            }
            @include breakpoint($extra-large-up) {
              min-width: 63px;
              padding-inline: 3px;
            }
            @include breakpoint($extra-extra-large-up) {
              min-width: 68px;
            }
            @include breakpoint($desktop-site-width) {
              min-width: 70px;
              padding-inline: 5px;
            }
            &.button--active {
              font-weight: 500;
            }
          }
          &__list {
            padding: 0;
            &.slick-slider:has(.slick-arrow) {
              padding: 0 15px;
              @include breakpoint($medium-up) {
                padding: 0 18px;
                z-index: 10;
              }
            }
            .slick-arrow {
              top: -5px;
              opacity: 1;
              margin-block-start: 0;
              &::before {
                font-size: 12px;
              }
              &.slick-prev {
                #{$ldirection}: -13px;
                @include breakpoint($medium-up) {
                  #{$ldirection}: -9px;
                }
              }
              &.slick-next {
                #{$rdirection}: -13px;
                @include breakpoint($medium-up) {
                  #{$rdirection}: -9px;
                }
              }
              .mpp-container &,
              .content-block-large__products & {
                top: 0;
              }
            }
          }
          .slick-track {
            display: flex;
          }
        }
      }
      &__rating {
        .product-rating {
          margin: 5px 0 8px;
          .pr-category-snippet {
            margin-block-start: 1px;
          }
          .pr-star-v4 {
            width: 14px;
            height: 14px;
          }
        }
      }
      &__extras {
        :not(.mpp-container) & {
          opacity: 1;
          visibility: visible;
        }
      }
      &__shade-picker {
        .product-shade-picker {
          width: 100%;
          &__shades {
            float: none;
            padding: 0;
            &.slick-slider:has(.slick-arrow) {
              padding: 0 18px;
              @include breakpoint($landscape-up) {
                padding: 0 13px;
              }
            }
            .slick-arrow {
              top: -5px;
              margin-block-start: 0;
              opacity: 1;
              &::before {
                font-size: 15px;
              }
              &.slick-prev {
                #{$ldirection}: -12px;
              }
              &.slick-next {
                #{$rdirection}: -12px;
              }
              .mpp-container &,
              .content-block-large__products & {
                top: 0;
              }
            }
            .slick-list {
              padding: 0;
            }
            .slick-track {
              top: 3px;
              #{$ldirection}: 2px;
            }
          }
          &__shade {
            max-width: 34px;
            height: auto;
            @include swap_direction(margin, 0 10px 9px 0);
            @include breakpoint($medium-up) {
              @include swap_direction(margin, 0 8px 6px 0);
            }
            &.active {
              .product-shade-picker__shade-inner {
                width: 28px;
                height: 18px;
                margin: 3px;
                @include breakpoint($landscape-up) {
                  margin-left: auto;
                  margin-right: auto;
                }
              }
            }
          }
          &__shade-inner {
            width: 34px;
            height: 24px;
            margin: 0;
          }
        }
      }
      &__footer {
        padding-inline: 15px;
        .mpp-container & {
          padding: 10px 15px;
          @include breakpoint($medium-up) {
            padding-block-start: 21px;
          }
        }
        &:has(.product-add-to-favorites) {
          bottom: -25px;
          display: grid;
          @include breakpoint($medium-up) {
            .no-touchevents & {
              display: none;
            }
          }
        }
      }
      &__extras-container {
        padding-#{$ldirection}: 18px;
        bottom: 0;
        .mpp-container & {
          bottom: -14px;
        }
        &:has(.product-add-to-favorites) {
          bottom: -25px;
          display: grid;
        }
      }
      .product-shadename {
        padding: 0 0 10px;
      }
      .product-name {
        font-size: 13px;
        line-height: 1.3;
      }
    }
  }
  &.product-grid--small-cols-2 {
    .product-brief {
      &--show-shade-size {
        .sku-list__button {
          min-width: 60px;
          line-height: 1.5;
          @include breakpoint($medium-up) {
            min-width: 70px;
            line-height: 1.2;
          }
        }
      }
      &__header {
        padding: 0 10px;
        @include breakpoint($medium-up) {
          padding: 0;
        }
      }
      &__footer {
        .mpp-container & {
          padding: 10px 12px;
          @include breakpoint($medium-up) {
            padding: 0 12px;
          }
        }
      }
    }
  }
  &.product-grid--theme-black {
    .product-brief--show-shade-size {
      .product-brief__cta {
        .button {
          color: $color-white;
          border-bottom-color: $color-border;
        }
      }
      .product-brief__sku {
        .sku-list__item {
          .button {
            background: $color-neutral-black;
            color: $color-white;
            &--active {
              border-color: $color-white;
            }
          }
        }
      }
    }
  }
}
