///
/// @file global/_mixins.scss
///
/// \brief Mixins are helpful snippets that let us not repeat ourselves
///
/// Mixins output css and style rules. Don't use a mixin where you could use
/// @extend instead.
///

///
/// Font Smoothing
/// Primarily used to improve rendering of light text on dark background
///
@mixin font-smoothing($value: '') {
  @if ($value == false) {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  } @else {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

@mixin font-face(
  $name,
  $path,
  $weight: null,
  $style: null,
  $display: null,
  $exts: woff2 svg woff ttf eot
) {
  $src: null;
  $extmods: (
    eot: '?',
    svg: '#' + str-replace($name, ' ', '_')
  );
  $formats: (
    otf: 'opentype',
    ttf: 'truetype'
  );
  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + '.' + $extmod)) format(quote($format)), comma);
  }
  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    font-display: $display;
    src: $src;
  }
}

@mixin font-size($font-size, $line-height: normal, $letter-spacing: normal, $font-weight: normal) {
  font-size: $font-size * 1px;
  font-weight: $font-weight;
  @if $line-height == normal {
    line-height: normal;
  } @else {
    line-height: $line-height / $font-size;
  }
  @if $letter-spacing == normal {
    letter-spacing: normal;
  } @else {
    letter-spacing: #{$letter-spacing * $font-size}em;
  }
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return number;
}

// calculate the scaled size based on the pixel size and breakpoint given
@function get-scaled-size($size, $breakpoint: $desktop-site-width, $target: $medium-up) {
  $scale: $size / $breakpoint * $target;
  @return floor($scale);
}

// calculate the vw unit based on the pixel size and breakpoint given
@function get-vw($size, $breakpoint: $mobile-site-width, $unit: vw) {
  $px: strip-unit($size);
  $screen-width: strip-unit($breakpoint);
  $vw: $px / $screen-width * 100;
  @return #{$vw}$unit;
}

@mixin fluid-sizing($props, $min-size, $max-size, $min-bp:$mobile-site-width, $max-bp:$desktop-site-width, $dimension: 'width', $include-small-device: true) {
  @if $include-small-device {
    @each $prop in $props {
      #{$prop}: $min-size;
    }
  }
  @media(min-#{$dimension}: $min-bp) {
    @each $prop in $props {
      #{$prop}: calc(#{$min-size} + #{strip-unit($max-size - $min-size)} * (100vw - #{$min-bp}) / #{strip-unit($max-bp - $min-bp)});
    }
  }
  @media(min-#{$dimension}: $max-bp) {
    @each $prop in $props {
      #{$prop}: $max-size;
    }
  }
}

///
/// Style input placeholders
///
@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
    opacity: 1 !important;
  } //opacity needed to override FF user agent
  &::-moz-placeholder {
    @content;
    opacity: 1 !important;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

///
/// Style input checkbox and input radio element
///
@mixin input-styled-check($icon, $suffix: 'checked', $padding: 7px) {
  // hide the actual element
  position: absolute;
  left: -9999px;
  ~ label,
  ~ .label {
    @include icon($icon);
    &::before {
      margin-right: $padding;
      margin-top: -2px;
    }
  }
  &:checked {
    ~ label,
    ~ .label {
      @include icon($icon + '--' + $suffix);
    }
  }
  // Add focus styles
  &:focus + label::before {
    outline: $color-focus auto 5px;
  }
}

///
/// Center Element on any Axis
/// https://css-tricks.com/snippets/sass/centering-mixin/them if it's important.
// NOTE: assumes parent element has position: relative;
@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    @include transform(translate(-50%, -50%));
    top: 50%;
    left: 50%;
  } @else if ($horizontal) {
    @include transform(translateX(-50%));
    left: 50%;
  } @else if ($vertical) {
    @include transform(translateY(-50%));
    top: 50%;
  }
}

@mixin destroy-centerer() {
  @include transform(translate(0, 0));
  position: static;
  top: auto;
  left: auto;
}

///
/// Vertical & Horizontal centerer
///
@mixin vertical-horizontal-center {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

%vertical-horizontal-center {
  @include vertical-horizontal-center;
}

// RTL support
@mixin swap_direction($type, $v) {
  @if length($v) == 4 {
    @if $text-direction == rtl {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    } @else {
      #{$type}: $v;
    }
  } @else {
    #{$type}: $v;
  }
}

// max-width centered container
@mixin content-container {
  margin-left: auto;
  margin-right: auto;
  max-width: $max-width;
  &--100 {
    max-width: 100%;
  }
  &--768 {
    max-width: 768px;
  }
  &--1024 {
    max-width: 1024px;
  }
  &--1280 {
    max-width: 1280px;
  }
  &--1440 {
    max-width: 1440px;
  }
  &--1920 {
    max-width: 1920px;
  }
}

///
/// Fluid Images for responsive designs
///
@mixin fluid-image {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
}

@mixin accordion-title {
  @include icon('plus', after);
  position: relative;
  cursor: pointer;
  text-align: left;
  height: 45px;
  font-family: $font--neue-haas-unica-pro;
  font-size: 14px;
  line-height: 1.5;
  @include breakpoint($landscape-up) {
    font-size: 15px;
  }
  &::after {
    @include centerer(false, true);
    right: 0;
    font-size: 14px;
  }
  &.active {
    @include icon('minus', after);
  }
}

@mixin clearfix {
  .clearfix::before,
  .clearfix::after {
    content: ' '; /* 1 */
    display: table; /* 2 */
  }
  .clearfix::after {
    clear: both;
  }
}

@mixin link {
  // @todo remove after roll-out (ASMBLY7-529)
  @if $cr22 {
    letter-spacing: 0.05em;
    text-transform: uppercase;
  } @else {
    letter-spacing: 0.075em;
    text-transform: uppercase;
  }
  @include fluid-sizing(font-size, 13px, 14px);
  font-family: $font--neue-haas-unica-pro;
  font-weight: 400;
  line-height: 1.6;
  color: $color-neutral-dark-gray;
  border-bottom: 1px solid $color-neutral-dark-gray;
  text-decoration: none;
  transition: color 0.3s, border-color 0.3s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}

@mixin link--secondary {
  @include link;
  font-size: 11px;
  font-size: 1.1rem;
}

// Typography
@mixin title--h1 {
  font-size: 24px;
  font-family: $font--malone;
  letter-spacing: get-letter-spacing(10);
  line-height: get-line-height(24px, 32px);
  @include breakpoint($landscape-up) {
    font-size: 32px;
    line-height: get-line-height(32px, 40px);
  }
}

@mixin title--h2 {
  font-size: 24px;
  font-family: $font--malone;
  letter-spacing: get-letter-spacing(10);
  line-height: get-line-height(24px, 32px);
}

@mixin title--h3 {
  font-size: 16px;
  font-family: $font--malone;
  line-height: get-line-height(16px, 24px);
  @include breakpoint($landscape-up) {
    font-size: 20px;
    line-height: get-line-height(20px, 32px);
  }
}

@mixin title--h4 {
  font-size: 16px;
  font-family: $font--malone;
  letter-spacing: get-letter-spacing(10);
  line-height: get-line-height(16px, 24px);
}

@mixin title--h5 {
  font-size: 12px;
  font-family: $font--lars;
  font-weight: 700;
  letter-spacing: get-letter-spacing(120);
  line-height: get-line-height(12px, 18px);
  text-transform: uppercase;
}

@mixin title--h6 {
  font-size: 12px;
  font-family: $font--lars;
  letter-spacing: get-letter-spacing(170);
  line-height: get-line-height(12px, 18px);
  text-transform: uppercase;
}

@mixin body-text {
  font-family: $font--lars;
  font-size: 14px;
  letter-spacing: get-letter-spacing(0);
  line-height: get-line-height(10px, 16px);
}

@mixin body-text2 {
  font-family: $font--lars;
  font-size: 12px;
  letter-spacing: get-letter-spacing(20);
  line-height: get-line-height(10px, 16px);
}

// Buttons
@mixin button {
  @include font-smoothing;
  @include appearance(none);
  // @todo remove after roll-out (ASMBLY7-529)
  @if $cr22 {
    @include typestyle--b1;
    background: $color-neutral-black;
    border-radius: 0;
    padding: 20px;
    min-width: 150px;
    @include breakpoint($landscape-up) {
      min-width: 180px;
    }
  } @else {
    background: $color-dark-gray;
    border-radius: 4px;
    padding: 13px 15px 14px;
    line-height: 1;
    font-size: 11px;
    min-width: 110px;
    text-transform: uppercase;
    @include breakpoint($landscape-up) {
      min-width: 140px;
    }
  }
  display: inline-block;
  color: $color-white;
  border: 1px solid $color-neutral-black;
  letter-spacing: 0.05em;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  transition: background 0.3s, color 0.3s, border-color 0.3s;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
  &:focus-visible {
    @include outline-none;
    border-color: $color-neutral-black;
  }
}

@mixin button--secondary {
  @include button;
  background: $color-white;
  color: $color-dark-gray;
  border-color: $color-lighter-gray;
}

@mixin button--green {
  @include button;
  background: $color-green;
  color: $color-white;
  border-color: $color-green;
  &:hover,
  &:active,
  &:focus {
    border-color: $color-dark-gray;
    background: $color-dark-gray;
  }
  &:focus-visible {
    @include outline-none;
    border-color: $color-green;
  }
}

@mixin button--large-white {
  @include button--secondary;
  width: 100%;
}

// Mostly used for forms, tabs or a selection of buttons
@mixin button--transparent {
  @include button--secondary;
  min-width: 0;
  background: transparent;
  white-space: nowrap;
  &:hover,
  &:active,
  &:focus {
    background: transparent;
    border-color: $color-black;
    color: $color-dark-gray;
  }
  &:focus-visible {
    @include outline-none;
    border-color: inherit;
  }
}

@mixin button--dark {
  @include button;
  color: $color-cream;
  background: $black;
  &:hover {
    color: $color-cream;
    text-decoration: none;
    box-shadow: none;
  }
}

@mixin button--light {
  @include button;
  color: $black;
  box-shadow: inset 0 0 0 1px $black;
  letter-spacing: 0;
  &:hover {
    text-decoration: underline;
  }
}

@mixin button--text {
  font-family: $font--lamer-text-oab;
  font-size: 16px;
  margin: 0;
  padding: 0;
  background: inherit;
  color: $black;
  box-shadow: none;
  border-bottom: 1px solid $black;
  border-radius: 0;
  text-transform: uppercase;
  height: 36px;
  &:hover {
    background: inherit;
    color: $black;
  }
  &:focus-visible {
    outline: 1px dashed $black;
  }
}

// 2021 font styles
@mixin typestyle--h1 {
  font-family: $font--lamer-headline;
  @include fluid-sizing(font-size, 42px, 120px);
  text-transform: uppercase;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.05em;
  font-weight: 600;
  @include breakpoint($landscape-up) {
    line-height: 0.875;
  }
}

@mixin typestyle--h2 {
  @include fluid-sizing(font-size, 42px, 82px);
  @include font--heading;
  text-transform: uppercase;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.05em;
  font-weight: 500;
  @include breakpoint($landscape-up) {
    line-height: 1;
  }
}

@mixin typestyle--h3 {
  @include font--heading;
  @include fluid-sizing(font-size, 42px, 52px);
  letter-spacing: -0.05em;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-weight: 500;
}

@mixin typestyle--h4 {
  @include font--heading;
  @include fluid-sizing(font-size, 32px, 40px);
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.03em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
}

@mixin typestyle--h5 {
  @include font--heading;
  @include fluid-sizing(font-size, 22px, 28px);
  letter-spacing: -0.03em;
  font-family: $font--lamer-headline;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.03em;
  font-weight: 500;
  text-transform: uppercase;
}

@mixin typestyle--h6 {
  @include font--heading;
  @include fluid-sizing(font-size, 26px, 26px);
  letter-spacing: -0.03em;
  line-height: 1.1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
}

@mixin typestyle--h7 {
  @include fluid-sizing(font-size, 20px, 21px);
  @include font--heading;
  letter-spacing: -0.03em;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-weight: 500;
}

@mixin typestyle--h8 {
  @include fluid-sizing(font-size, 13px, 14px);
  letter-spacing: 0.05em;
  font-family: $font--neue-haas-unica-pro;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-weight: 400;
}

@mixin typestyle--h9 {
  @include fluid-sizing(font-size, 13px, 14px);
  letter-spacing: 0.05em;
  font-family: $font--neue-haas-unica-pro-medium;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-weight: 400;
  @include breakpoint($landscape-up) {
    font-weight: 400;
  }
}

@mixin typestyle--h10 {
  @include fluid-sizing(font-size, 12px, 12px);
  letter-spacing: 0.05em;
  font-family: $font--neue-haas-unica-pro;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  @include breakpoint($landscape-up) {
    font-weight: 400;
  }
}

@mixin typestyle--b1 {
  font-family: $font--neue-haas-unica-pro;
  @include fluid-sizing(font-size, 14px, 15px);
  line-height: 1.5;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

@mixin typestyle--b2 {
  @include fluid-sizing(font-size, 14px, 15px);
  font-family: $font--neue-haas-unica-pro-medium;
  font-weight: 400;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: normal;
  @include breakpoint($landscape-up) {
    font-weight: 400;
  }
}

@mixin typestyle--b3 {
  @include fluid-sizing(font-size, 12px, 12px);
  letter-spacing: normal;
  font-family: $font--neue-haas-unica-pro;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  @include breakpoint($landscape-up) {
    letter-spacing: 0.01em;
  }
}

// Gradient support
@mixin gradient-background($color-gradient-setting) {
  background: linear-gradient($color-gradient-setting);
}

// Elevation
@mixin elevation-level-1 {
  box-shadow: 0 2px 10px rgba(48, 48, 48, 0.05);
}

// Container related vertical margin rules
@mixin container-vertical-rules($dir: top) {
  .container-vertical-#{$dir}-margin {
    &--8 {
      margin-#{$dir}: 8px;
    }
    &--16 {
      margin-#{$dir}: 16px;
    }
    &--20 {
      margin-#{$dir}: 20px;
    }
    &--32 {
      margin-#{$dir}: 32px;
    }
    &--40 {
      margin-#{$dir}: 40px;
    }
    &--60-80 {
      margin-#{$dir}: 60px;
      @include breakpoint($landscape-up) {
        margin-#{$dir}: 80px;
      }
    }
    &--80-120 {
      margin-#{$dir}: 80px;
      @include breakpoint($landscape-up) {
        margin-#{$dir}: 120px;
      }
    }
    &--100-160 {
      margin-#{$dir}: 100px;
      @include breakpoint($landscape-up) {
        margin-#{$dir}: 160px;
      }
    }
    &--120-240 {
      margin-#{$dir}: 120px;
      @include breakpoint($landscape-up) {
        margin-#{$dir}: 240px;
      }
    }
    &--160 {
      margin-#{$dir}: 160px;
    }
    &--180 {
      margin-#{$dir}: 180px;
    }
  }
}

// Outline style on focused elements for keyboard accessibility.
@mixin outline {
  outline: 1px solid $color-focus !important;
}

@mixin outline-none {
  outline: none !important;
}

// add vertical padding & RTL variations on one line
@mixin x-padding($left, $right: false) {
  padding-#{$ldirection}: $left;
  @if ($right != false) {
    padding-#{$rdirection}: $right;
  } @else {
    padding-#{$rdirection}: $left;
  }
}

// checkout related mixins

// Main header/title style
@mixin checkout-main-heading {
  font-family: $header-font-family;
  font-size: get-rem(36px);
  letter-spacing: 0;
  line-height: get-line-height(36px, 54px);
}

// secondary header/title style
@mixin checkout-secondary-heading {
  font-family: $header-font-family;
  font-size: get-rem(20px);
  letter-spacing: 0;
  line-height: get-line-height(20px, 30px);
}

// sub header/title style - medium version
@mixin checkout-sub-heading--medium {
  font-family: $header-font-family;
  font-size: get-rem(18px);
  font-weight: 300;
  letter-spacing: 0;
  line-height: get-line-height(18px, 24px);
  text-transform: none;
}

// sub header/title style - small version - style 1
@mixin checkout-sub-heading--small {
  font-family: $header-font-family;
  font-size: get-rem(12px);
  line-height: get-line-height(12px, 18px);
}

// sub header/title style - small version - style 2
@mixin checkout-sub-heading--small-style2 {
  font-family: $bold-font-family;
  font-size: get-rem(12px);
  letter-spacing: 1.7px;
  line-height: get-line-height(12px, 20px);
  text-transform: uppercase;
}

// default styling for paragraphs/body text
@mixin checkout-body-text--medium {
  font-family: $main-font;
  font-size: get-rem(14px);
  letter-spacing: 0;
  line-height: get-line-height(14px, 21px);
}

// default styling for paragraphs/body text bold
@mixin checkout-body-text--bold {
  font-family: $bold-font-family;
  font-size: get-rem(14px);
  letter-spacing: 0;
  line-height: get-line-height(14px, 24px);
}

// default styling for smaller paragraphs/body text
@mixin checkout-body-text--small {
  font-family: $main-font;
  font-size: get-rem(12px);
  line-height: get-line-height(12px, 14px);
}

// default styling for smaller product name
@mixin checkout-product-name-bold {
  font-family: $main-font;
  font-size: get-rem(14px);
  font-weight: 600;
  line-height: get-line-height(12px, 18px);
}

///
/// Triangles - creates right triangles with border-clipping tricks.
///
/// Adapted from this: http://codepen.io/vieron/pen/xuHDA
///
/// Usage:
/// @include triangle(size, color, direction)
/// @include bordered-triangle(size, color, direction,
///border-color, [border-width], [cut])
/// size: The length of the hypotenuse of the triangle
/// color: The fill color of the triangle
/// direction: Which way the arrow points
///
/// border-color: The color of the border around the triangle
/// border-width: The width of the border around the triangle
/// cut: Removes the border along the hypotenuse
///
@mixin triangle($size, $color, $direction) {
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: $size / 2;
  @if $direction == up {
    border-bottom-color: $color;
  } @else if $direction == right {
    border-left-color: $color;
  } @else if $direction == down {
    border-top-color: $color;
  } @else if $direction == left {
    border-right-color: $color;
  }
}

@mixin button-no-border {
  border-radius: 0;
  font-size: 15px;
  font-family: $main-font;
  padding: 20px;
}

// Mixin for the sentense case applied to the element.
@mixin to-sentence-case {
  display: inline-block;
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
}
